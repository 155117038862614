import { Container, injectable } from 'inversify';
import { EventBusModule } from '@common/event-bus-module';
import { StorageModule } from '@common/storage-module';
import { ProductLandingPageService } from '@pages/LandingPage/services/index';
import { AppNavigatorService } from '@/AppNavigator/services/index';
import { VendingMachinePageService } from '@pages/VendingMachinePage/services';
import { LandingPageService } from '@/pages/ProductLandingPage/services';
import { ProfilePageService } from '@/pages/ProfilePage/services';
import { OrganizationProfilePageService } from '@/pages/OrganizationProfile/services';
import { AdminPageService } from '@/pages/AdminPage/services';
import { SignInPageService } from '@/pages/SignIn/services';
// import { ProvisionalPageService } from '@/pages/ProvisionalPage/services';
import { ActualPageService } from '@/pages/ActualPage/services';
// import { ArchivedPageService } from '@/pages/ArchivedPolicy/services';
import { HoldersPageService } from '@/pages/HolderPage/services';
import { ActualDetailPageService } from '@/pages/ActualDetailPage/services';
// import { ProvisionalDetailPageService } from '@/pages/ProvisionalDetailPage/services';
import { HolderDetailPageService } from '@/pages/HoldersDetailPage/services';
import { AboutMePageService } from '@/pages/AboutMePage/services';
import { AnalyticsPageService } from '@/pages/AnalyticsPage/services';
// import { PayoutPageService } from '@/pages/PayoutPage/services';
import { PayoutListPageService } from '@/pages/PayoutListPage/services';
import { PayoutDetailPageService } from '@/pages/PayoutDetailPage/services';
import { EventTrackerPageService } from '@/pages/EventTrackerPage/services';
const vappContainer = new Container();

//Modules
vappContainer
  .bind<EventBusModule>(EventBusModule.displayName)
  .to(EventBusModule)
  .inSingletonScope();
vappContainer
  .bind<StorageModule>(StorageModule.displayName)
  .to(StorageModule)
  .inSingletonScope();

//Services
vappContainer
  .bind<ProductLandingPageService>(ProductLandingPageService.displayName)
  .to(ProductLandingPageService)
  .inSingletonScope();
vappContainer
  .bind<AppNavigatorService>(AppNavigatorService.displayName)
  .to(AppNavigatorService)
  .inSingletonScope();
vappContainer
  .bind<VendingMachinePageService>(VendingMachinePageService.displayName)
  .to(VendingMachinePageService)
  .inSingletonScope();
vappContainer
  .bind<LandingPageService>(LandingPageService.displayName)
  .to(LandingPageService)
  .inSingletonScope();
vappContainer
  .bind<ProfilePageService>(ProfilePageService.displayName)
  .to(ProfilePageService)
  .inSingletonScope();
vappContainer
  .bind<OrganizationProfilePageService>(
    OrganizationProfilePageService.displayName
  )
  .to(OrganizationProfilePageService)
  .inSingletonScope();
vappContainer
  .bind<AdminPageService>(AdminPageService.displayName)
  .to(AdminPageService)
  .inSingletonScope();
// vappContainer
// .bind<ProvisionalPageService>(ProvisionalPageService.name)
// .to(ProvisionalPageService)
// .inSingletonScope();
vappContainer
  .bind<ActualPageService>(ActualPageService.displayName)
  .to(ActualPageService)
  .inSingletonScope();
// vappContainer
// .bind<ArchivedPageService>(ArchivedPageService.name)
// .to(ArchivedPageService)
// .inSingletonScope();
vappContainer
  .bind<HoldersPageService>(HoldersPageService.displayName)
  .to(HoldersPageService)
  .inSingletonScope();
vappContainer
  .bind<ActualDetailPageService>(ActualDetailPageService.displayName)
  .to(ActualDetailPageService)
  .inSingletonScope();
// vappContainer
// .bind<ProvisionalDetailPageService>(ProvisionalDetailPageService.name)
// .to(ProvisionalDetailPageService)
// .inSingletonScope();
vappContainer
  .bind<HolderDetailPageService>(HolderDetailPageService.displayName)
  .to(HolderDetailPageService)
  .inSingletonScope();
vappContainer
  .bind<AboutMePageService>(AboutMePageService.displayName)
  .to(AboutMePageService)
  .inSingletonScope();
vappContainer
  .bind<AnalyticsPageService>(AnalyticsPageService.displayName)
  .to(AnalyticsPageService)
  .inSingletonScope();
// vappContainer
// .bind<PayoutPageService>(PayoutPageService.name)
// .to(PayoutPageService)
// .inSingletonScope();
vappContainer
  .bind<PayoutListPageService>(PayoutListPageService.displayName)
  .to(PayoutListPageService)
  .inSingletonScope();
vappContainer
  .bind<PayoutDetailPageService>(PayoutDetailPageService.displayName)
  .to(PayoutDetailPageService)
  .inSingletonScope();
vappContainer
  .bind<SignInPageService>(SignInPageService.displayName)
  .to(SignInPageService)
  .inSingletonScope();
vappContainer
  .bind<EventTrackerPageService>(EventTrackerPageService.displayName)
  .to(EventTrackerPageService)
  .inSingletonScope();

export { vappContainer };
