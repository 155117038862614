//@ts-nocheck
import React from 'react';
import {
  CheckCircleIcon,
  DotsHorizontalIcon,
  EyeIcon,
  InformationCircleIcon,
  BackspaceIcon,
  XCircleIcon,
} from '@heroicons/react/solid';

import { toHeaderCase } from 'js-convert-case';

import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  FormCheck,
  OverlayTrigger,
  Tooltip,
  Image,
} from 'react-bootstrap';
import moment from 'moment-timezone';
import * as S from './styles';
import { ToggleAction } from './ToggleAction';
const capitalizeFirstLetter = (string) =>
  string[0].toUpperCase() + string.slice(1);

const getFirstLetterOfEachWord = (text) => text?.match(/\b\w/g).join('');

export const DomainTableRow = (props) => {
  const {
    id,
    verified,
    status,
    kycStatus,
    occupation,
    group,
    image,
    name,
    email,
    dateCreated,
    isSelected,
    selectUser,
    deleteUsers,
    telephone,
    city,
    country,
    onViewDetailClick,
    onRevokeClick,
    onRowClick,
    viewTitle,
  } = props;
  // console.log("date created =>", dateCreated);
  const VerifiedIcon = verified ? CheckCircleIcon : InformationCircleIcon;

  const variantColor =
    kycStatus === 'VERIFIED'
      ? '#00AB08'
      : kycStatus === 'SUBMITTED'
      ? '#FE8D01'
      : kycStatus === 'REJECTED'
      ? '#DC3545'
      : '#0000FF';

  const statusColor =
    status === 'active'
      ? '#00AB08'
      : status === 'created'
      ? '#FE8D01'
      : status === 'suspended'
      ? '#DC3545'
      : status === 'verified'
      ? '#691783'
      : '#0000FF';

  const statusVariant =
    status === 'active'
      ? 'success'
      : status === 'inactive'
      ? 'warning'
      : status === 'pending'
      ? 'purple'
      : status === 'revoked'
      ? 'danger'
      : 'primary';
  const onToggle = (key: string) => {
    console.log(`toggle in row`, key, email);
    onTogglePress && onTogglePress(key, email);
  };
  const onUserChecked = (event: any, id: string) => {
    selectUser && selectUser(id);
    event.stopPropagation();
  };

  return (
    <tr
      className="border-bottom"
      onClick={() => onRowClick(id)}
      style={{ cursor: 'pointer' }}
    >
      <td>
        <FormCheck type="checkbox" className="dashboard-check">
          <FormCheck.Input
            id={`user-${id}`}
            checked={isSelected}
            onClick={(e) => onUserChecked(e, id)}
          />
          <FormCheck.Label htmlFor={`user-${id}`} />
        </FormCheck>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          {image ? (
            <Image src={image} className="avatar rounded-circle me-3" />
          ) : (
            <div className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3">
              <span>{getFirstLetterOfEachWord(name)}</span>
            </div>
          )}
          <div className="d-block">
            <span className="fw-bold">{name}</span>
            <div className="small text-gray">{email}</div>
          </div>
        </Card.Link>
      </td>
      <td>
        <span className={`fw-normal`}>{dateCreated}</span>
      </td>
      <td>
        {kycStatus === 'VERIFIED' ||
        kycStatus === 'SUBMITTED' ||
        kycStatus === 'REJECTED' ? (
          <>
            <span className={`fw-normal`}>
              <S.ButtonComp
                className={`btn rounded-pill btn-sm`}
                color={variantColor}
              >
                {toHeaderCase(kycStatus)}
              </S.ButtonComp>
            </span>
          </>
        ) : (
          <>
            <p>-</p>
          </>
        )}
      </td>
      <td>
        <span className={`fw-normal`}>
          <S.ButtonComp
            className={`btn rounded-pill btn-sm`}
            color={statusColor}
          >
            {toHeaderCase(status)}
          </S.ButtonComp>
        </span>
      </td>
      <td>
        <ToggleAction status={status} onTogglePress={onToggle} />
        {/* <OverlayTrigger
          placement="top"
          overlay={<Tooltip className="m-0">Delete</Tooltip>}
        >
          <Card.Link
            className="ms-2"
            onClick={() => deleteUsers && deleteUsers(email)}
          >
            <XCircleIcon className="icon icon-xs text-danger" />
          </Card.Link>
        </OverlayTrigger> */}
      </td>

      {/* <td>
      <Card.Link className="d-flex align-items-center">
        <span className="fw-normal">
          {moment(dateCreated).format("MMMM Do YYYY, h:mm:ss a")}
        </span>
        </Card.Link>
      </td> */}
    </tr>
  );
};

DomainTableRow.defaultProps = {
  viewTitle: 'View Certificate',
};
