//@ts-nocheck
import React from 'react';
import {
  CheckCircleIcon,
  DotsHorizontalIcon,
  EyeIcon,
  InformationCircleIcon,
  BackspaceIcon,
  XCircleIcon,
} from '@heroicons/react/solid';

import { toHeaderCase } from 'js-convert-case';
import { ToggleAction } from './ToggleAction';

import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  FormCheck,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import moment from 'moment-timezone';
import * as S from './styles';
import '../flag-icons/css/flag-icons.min.css';

const getFirstLetterOfEachWord = (text) => text?.match(/\b\w/g).join('');

export const DomainTableRow = (props) => {
  const {
    id,
    rowData,
    vid,
    verified,
    status,
    policyNo,
    group,
    image,
    name,
    email,
    dateCreated,
    isSelected,
    selectUser,
    country,
    countryCode,
    coverage,
    premium,
    discount,
    policyOptions,
    onViewDetailClick,
    onRevokeClick,
    onRowClick,
    viewTitle,
  } = props;
  const VerifiedIcon = verified ? CheckCircleIcon : InformationCircleIcon;
  const statusVariant =
    status === 'active'
      ? 'success'
      : status === 'inactive'
      ? 'warning'
      : status === 'pending'
      ? 'purple'
      : status === 'revoked'
      ? 'danger'
      : 'primary';
  const onToggle = (key: string) => {
    console.log(`toggle in row`, key, email);
    onTogglePress && onTogglePress(key, email);
  };
  const onUserChecked = (event: any, id: string) => {
    selectUser && selectUser(id);
    event.stopPropagation();
  };

  return (
    <tr className="border-bottom" onClick={() => onRowClick(rowData)}>
      <td>
        <FormCheck type="checkbox" className="dashboard-check">
          <FormCheck.Input
            id={`user-${id}`}
            checked={isSelected}
            onClick={(e) => onUserChecked(e, id)}
          />
          <FormCheck.Label htmlFor={`user-${id}`} />
        </FormCheck>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          {image ? (
            <Image src={image} className="avatar rounded-circle me-3" />
          ) : (
            <div className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3">
              <span>{getFirstLetterOfEachWord(name)}</span>
            </div>
          )}
          <div className="d-block">
            <span className="fw-bold">{name}</span>
            <div className="small text-gray">{email}</div>
          </div>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{policyNo}</span>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{coverage}</span>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{premium}</span>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span class={`fi fi-${countryCode} fis`}> </span>{' '}
          <span className="fw-normal px-1">{country}</span>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{toHeaderCase(policyOptions ?? '')}</span>
        </Card.Link>
      </td>

      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{dateCreated}</span>
        </Card.Link>
      </td>
      <td>
        <ToggleAction status={status} onTogglePress={onToggle} />
      </td>
    </tr>
  );
};

DomainTableRow.defaultProps = {
  viewTitle: 'View Certificate',
};
